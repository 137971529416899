@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

// Special Events Main Container
.special-events {
  padding: 0.25rem 2rem 0.5rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);

  @include tablet {
    padding: 0.5rem 4rem 7rem;
  }

  @include desktop {
    padding: 0.5rem 14rem 8rem;
  }

  // Header Section
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    text-align: center;
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    padding-right: 0.75rem;
    font-size: 1.75rem;

    @include tablet {
      padding-right: 3rem;
      font-size: 2.5rem;
    }

    @include desktop {
      font-size: 3rem;
    }
  }

  // Intro Section
  &__intro {
    text-align: center;
    margin: 1rem auto 1rem;
    padding: 0.75rem;
    background: linear-gradient(
      135deg,
      rgba(255, 240, 240, 0.7),
      rgba(255, 255, 255, 0.5)
    );
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @include tablet {
      padding: 1rem;
      max-width: 33rem;
    }

    @include desktop {
      padding: 1.5rem 2rem;
      max-width: 43rem;
    }

    &-text {
      color: #333;
      font-weight: 700;
      line-height: 1.4;

      @include tablet {
        font-size: 1rem;
      }

      @include desktop {
        font-size: 1.25rem;
      }

      &::before,
      &::after {
        content: "✨";
        margin: 0 0.25rem;
        font-size: 1rem;

        @include desktop {
          font-size: 2rem;
        }
      }
    }
  }

  // Image Container
  &__image-container {
    position: relative;
    width: 100%;
    max-width: 600px; // Prevent the container from exceeding screen width
    margin: 1rem auto;
    display: flex; // Added to center the button
    flex-direction: column;
    justify-content: center; // Horizontally center
    align-items: center; // Vertically center
    gap: 1rem;

    @include tablet {
      width: 80%;
    }

    @include desktop {
      width: 60%;
    }
  }

  &__image {
    width: 100%;
    max-width: 100%; // Prevent image from exceeding container size
    height: auto; // Maintain aspect ratio
    border-radius: 8px;
    margin: 1rem;
  }

  // Button Styling
  &__button {
    display: flex;
    justify-content: center;
    align-items: center; // Ensures the text inside the button is centered
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    background: linear-gradient(135deg, #976ed9, #a98fd5); // Original gradient
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background: linear-gradient(
        135deg,
        #b183e7,
        #9c74e4
      ); // Original hover gradient
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(151, 110, 217, 0.8);
    }
  }
}
