@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.birthday-party {
  padding: 0.25rem 2rem 0.5rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);

  @include tablet {
    padding: 0.5rem 4rem 7rem;
  }

  @include desktop {
    padding: 0.5rem 14rem 8rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }

    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
    padding-right: 1.25rem;
    font-size: 1.75rem;
    @include tablet {
      padding-right: 3rem;
    }
    @include desktop {
      padding-right: 5rem;
    }
  }

  &__container {
    @include tablet {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
    }
  }

  &__image {
    width: 100%;
    display: block;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    @include tablet {
      width: 20.5rem;
    }

    @include desktop {
      width: 42rem;
    }
  }

  &__subtitle {
    color: #000;
    margin: 0.5rem 0;
    text-align: left;
    line-height: 1.3;
    @include tablet {
      font-size: 1.05rem;
      margin: 0 0 0.5rem;
    }
    @include desktop {
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
  }
}
